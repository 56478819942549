import React, { useState } from 'react';
import './TitleList.css';
import getImagePath from '../utils/getImagePath';
import { isMobileAtom, languageAtom } from '../App';
import { useAtom } from 'jotai';
import getLocalizedText from '../utils/getLocalizedText';
import { useMediaQuery } from 'react-responsive';

const TitleList = ({ containerType, title, titleType, itemType, items, subitems, bg }) => {

    const isMobileWidth = useMediaQuery({ query: '(max-width: 768px)' })
    const [isMobile] = useAtom(isMobileAtom);
    const [language] = useAtom(languageAtom);
    const imageTopMo = getImagePath(62);
    const imageBotMo = getImagePath(77);

    const imageTopPC = getImagePath(36);
    const imageBotPC = getImagePath(48);

    const imageMid = getImagePath('DOT_BG_TILE');

    const imagePathMo = getImagePath(68);
    const imagePathPC = getImagePath(39);

    const imagePath = isMobile ? imagePathMo : imagePathPC;
    const imageTop = isMobile ? imageTopMo : imageTopPC;
    const imageBot = isMobile ? imageBotMo : imageBotPC;

    const imageFlexBG = '/Mobile/MobileImage_126.png'
    const imageFirst = '/PC/Image_94.png'

    const handleCopyToClipboard = (text) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                alert("Link copied to clipboard!");
            })
            .catch(err => {
                console.error("Failed to copy: ", err);
            });
    };

    const bgSize = isMobile ? "375px" : "2100px";
    const bgSize2 = isMobile ? "45px" : "81px";


    const bgPos = isMobile ? "0px top" : "4px top"
    const bgPos2 = isMobile ? "2px 10px" : "2px 30px"

    return (
        <div className={`title-list-container ${containerType}`} style={{
            ...(bg
                ? {
                    backgroundImage: itemType === 1
                        ? `url(${imageTop}), url(${imageMid})`
                        : `url(${imageTop}), url(${imageMid})`,
                    backgroundSize: `${bgSize} auto, ${bgSize2}`,
                    backgroundRepeat: 'repeat-x, repeat',
                    backgroundPosition: `${bgPos}, ${bgPos2}`
                }
                : {
                    backgroundImage: `url(${imageBot})`,
                    backgroundSize: `${bgSize} auto`,
                    backgroundPosition: '0px top'
                }),
            width: '100%',  // 가로로 전체 화면 차지
        }}>
            {!isMobile ?
                <>{titleType !== 'hidden' && (
                    <div className={`item ${titleType}`}>
                        {titleType === 'left' && (
                            <img
                                src={imagePath}
                                alt="Left"
                                className="item-image-left"
                            />
                        )}
                        <span
                            className={`item-text ${containerType === 'container-1' ? 'text1' : 'texts'}`}
                            dangerouslySetInnerHTML={{ __html: title }}
                        />
                        {titleType === 'right' && (
                            <img
                                src={imagePath}
                                alt="Right"
                                className="item-image-right flipped"
                            />
                        )}
                    </div>
                )}
                </>
                :
                <>
                    {titleType !== 'hidden' && containerType !== 'container-6'  && (
                        <div className={`item-with-mark`}>
                        <span
                            className={`item-text ${containerType === 'container-1' ? 'text1' : 'texts'}`}
                            dangerouslySetInnerHTML={{ __html: title }}
                        />
                        <div
                            className="item-image"
                            style={{
                                backgroundImage: `url(${imagePath})`, // 동적 이미지 경로
                            }}
                        />
                        </div>
                    )}</>
            }

            {(itemType === 0 &&
                <>
                    <ul className="list">
                        {items != null && items.map((item, index) => (
                            <li key={index} className="list-item">
                                <img src={item} alt={`Item ${index}`} className="list-item-image" />
                            </li>
                        ))}
                    </ul>
                    {(subitems != null &&
                        <ul className="list-sub">
                            {!isMobile ? <>
                                {subitems.map((item, index) => (
                                    <li key={index} className="list-sub-item">
                                        <img src={item} alt={`Item ${index}`} className="list-sub-item-image" />
                                    </li>
                                ))}
                            </>
                                : <>
                                    <ul className="list-sub-div">
                                        <li className="list-sub-item">
                                            <img src={subitems[0]} alt={`Item ${0}`} className="list-sub-item-image" />
                                        </li>
                                        <li className="list-sub-item">
                                            <img src={subitems[2]} alt={`Item ${2}`} className="list-sub-item-image" />
                                        </li>
                                        <li className="list-sub-item">
                                            <img src={subitems[0]} alt={`Item ${0}`} className="list-sub-item-image" />
                                        </li>
                                    </ul>
                                    <ul className="list-sub-div2">
                                        <li className="list-sub-item">
                                            <img src={subitems[3]} alt={`Item ${3}`} className="list-sub-item-image" />
                                        </li>
                                        <li className="list-sub-item">
                                            <img src={subitems[4]} alt={`Item ${4}`} className="list-sub-item-image" />
                                        </li>
                                    </ul>
                                </>}
                        </ul>)}
                </>
            )}
            {(itemType === 1 &&
                <>
                    <h2 className="thumbnail-desc" dangerouslySetInnerHTML={{ __html: getLocalizedText(`ThumbnailsDesc`, language) }} id='POC' />

                    {isMobile && (
                        <h2 className="thumbnail-desc" >
                            {getLocalizedText("ContactLinkDesc")} <a href={getLocalizedText("ContactLink")} target="_blank" rel="noopener noreferrer">
                                {getLocalizedText("ContactLink")}
                            </a>
                        </h2>
                    )}
                    {!isMobile ?
                        <div className="custom-flex">
                            <div className="flex-item center" >
                                <img src={items[4]} alt="Center" className="flex-item-image" />
                            </div>
                            <div className="flex-item top-left" style={{ backgroundImage: `url('/Mobile/MobileImage_126.png')` }}>
                                <img src={items[0]} alt="Top Left" className="flex-item-gif" />
                            </div>
                            <div className="flex-item top-right" style={{ backgroundImage: `url('/Mobile/MobileImage_126.png')` }}>
                                <img src={items[2]} alt="Top Right" className="flex-item-gif" />
                            </div>
                            <div className="flex-item bottom-left" style={{ backgroundImage: `url('/Mobile/MobileImage_126.png')` }}>
                                <img src={items[1]} alt="Bottom Left" className="flex-item-gif" />
                            </div>
                            <div className="flex-item bottom-right" style={{ backgroundImage: `url('/Mobile/MobileImage_126.png')` }}>
                                <img src={items[3]} alt="Bottom Right" className="flex-item-gif" />
                            </div>
                        </div>
                        :
                        <div className="custom-flex" >
                            <div className="flex-item" >
                                <img src={imageFirst} alt="Center" className="flex-item-image" />
                            </div>
                            <div className="flex-item" style={{ backgroundImage: `url('/Mobile/MobileImage_126.png')` }}>
                                <img src={items[0]} alt="Top Left" className="flex-item-gif" />
                            </div>
                            <div className="flex-item" style={{ backgroundImage: `url('/Mobile/MobileImage_126.png')` }}>
                                <img src={items[2]} alt="Top Right" className="flex-item-gif" />
                            </div>
                            <div className="flex-item" style={{ backgroundImage: `url('/Mobile/MobileImage_126.png')` }}>
                                <img src={items[1]} alt="Bottom Left" className="flex-item-gif" />
                            </div>
                            <div className="flex-item" style={{ backgroundImage: `url('/Mobile/MobileImage_126.png')` }}>
                                <img src={items[3]} alt="Bottom Right" className="flex-item-gif" />
                            </div>
                        </div>}
                </>
            )
            }
        </div>
    );
};

export default TitleList;
