// src/components/Header.js
import React, { useEffect, useState } from 'react';
import './Header.css';
import getImagePath from '../utils/getImagePath';
import { useAtom } from 'jotai';
import { isMobileAtom } from '../App';
import { languageAtom } from '../App'; 
import { useMediaQuery } from 'react-responsive'

const Header = () => {
    const [language, setLanguage] = useAtom(languageAtom);
    const [logo, setLogo] = useState('');
    const [progressImage, setProgressImage] = useState('');
    const [isMobile, setIsMobile] = useAtom(isMobileAtom);
    const isMobileWidth = useMediaQuery({ query: '(max-width: 768px)' })
    const handleLanguageChange = (lang) => {
        setLanguage(lang);
    };

    const updateImages = () => {
        setLogo(getImagePath('03'));
        setProgressImage(getImagePath('05'));
    };


    useEffect(() => {
        updateImages(); // 초기 로고 설정
        window.addEventListener('resize', updateImages);
        return () => {
            window.removeEventListener('resize', updateImages);
        };
    }, [setIsMobile]);

    const onBottom = () => {
        const element = document.getElementById('POC');
        if (element)
        {
            const elementPosition = element.getBoundingClientRect().top + window.scrollY; // 요소의 위치 계산
            const offsetPosition = elementPosition + (isMobile ? -100 : -200);
            // element?.scrollIntoView({
            //     top:offsetPosition,
            //     behavior: 'smooth'
            // }); 

        window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
        });
        }
    }

    const onTop = () => {
        window.scroll({
            top: 0,
            behavior: 'smooth'
          });
    }
    console.log("isMobileWidth : ",isMobileWidth);
    return (
        <header className="header">
            <div className="logo" >
                <img src={logo} alt="Logo" onClick={onTop} />
            </div>
            <nav className="nav">
                {!isMobileWidth &&
                <div className="language-toggle">
                    <div>Language&nbsp;&nbsp;: </div>
                    <button
                        className={language === 'Jp' ? 'selected' : ''}
                        onClick={() => handleLanguageChange('Jp')}
                    >
                        Japanese
                    </button>
                    <button
                        className={language === 'En' ? 'selected' : ''}
                        onClick={() => handleLanguageChange('En')}
                    >
                        English
                    </button>
                    <button
                        className={language === 'Ko' ? 'selected' : ''}
                        onClick={() => handleLanguageChange('Ko')}
                    >
                        Korean
                    </button>
                </div>
                }
                {isMobileWidth && 
                <div class="langauge-select">
                <select class="language-select-selector" onChange={(e)=>{
                    handleLanguageChange(e.target.value);
                    e.target.blur();
                    }}>
                    <option value="Jp">Japanese</option>
                    <option value="En" selected>English</option>
                    <option value="Ko">Korean</option>
                </select>
                </div>
                }
                <button
                    className="progress-button" onClick={onBottom}
                >
                        POC PROGRESS
                </button>
            </nav>
        </header>
    );
};

export default Header;
