// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
    text-align: center;
    color: #818181;
    font-family:  'NanumSquare';
    font-weight: 800;
    font-size: 16.5px;
    margin-bottom: 120px;
  }

  

@media (max-width: 768px)
{
  .footer
  {
    padding-left: 3px;
    padding-right: 3px;
    font-size: 9px;
    letter-spacing: -0.2px;
    margin-bottom: 60px;

  }
}`, "",{"version":3,"sources":["webpack://./src/components/Footer.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,cAAc;IACd,2BAA2B;IAC3B,gBAAgB;IAChB,iBAAiB;IACjB,oBAAoB;EACtB;;;;AAIF;;EAEE;;IAEE,iBAAiB;IACjB,kBAAkB;IAClB,cAAc;IACd,sBAAsB;IACtB,mBAAmB;;EAErB;AACF","sourcesContent":[".footer {\n    text-align: center;\n    color: #818181;\n    font-family:  'NanumSquare';\n    font-weight: 800;\n    font-size: 16.5px;\n    margin-bottom: 120px;\n  }\n\n  \n\n@media (max-width: 768px)\n{\n  .footer\n  {\n    padding-left: 3px;\n    padding-right: 3px;\n    font-size: 9px;\n    letter-spacing: -0.2px;\n    margin-bottom: 60px;\n\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
