// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/styles/Content.css */

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: baseline;
  min-height: 100vh;
}

.maintitle {
  font-size: 70px;
  margin-bottom: 120px;
  font-family: 'CoreSansC-75ExtraBold.ttf', 'NotoSansKR-ExtraBold.ttf', 굴림;
  font-weight: 800;
}

.blue-text {
  color: #3f93eb;
}

.create-avatar-button {
  border: none;
  background-color: #3f93eb;
  cursor: pointer;
  border-radius: 8px;
  width: 240px;
  height: 40px;
  font-size: 16px;
  color: white;
}

@media (max-width: 768px) {
  .maintitle {
    font-size: 24px;
    letter-spacing: -2px;
    margin-bottom: 30px;
    text-align: center;
    margin-top: 40px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Content.css"],"names":[],"mappings":"AAAA,2BAA2B;;AAE3B;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,yBAAyB;EACzB,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,oBAAoB;EACpB,wEAAwE;EACxE,gBAAgB;AAClB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,YAAY;EACZ,yBAAyB;EACzB,eAAe;EACf,kBAAkB;EAClB,YAAY;EACZ,YAAY;EACZ,eAAe;EACf,YAAY;AACd;;AAEA;EACE;IACE,eAAe;IACf,oBAAoB;IACpB,mBAAmB;IACnB,kBAAkB;IAClB,gBAAgB;EAClB;AACF","sourcesContent":["/* src/styles/Content.css */\n\n.content {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: baseline;\n  min-height: 100vh;\n}\n\n.maintitle {\n  font-size: 70px;\n  margin-bottom: 120px;\n  font-family: 'CoreSansC-75ExtraBold.ttf', 'NotoSansKR-ExtraBold.ttf', 굴림;\n  font-weight: 800;\n}\n\n.blue-text {\n  color: #3f93eb;\n}\n\n.create-avatar-button {\n  border: none;\n  background-color: #3f93eb;\n  cursor: pointer;\n  border-radius: 8px;\n  width: 240px;\n  height: 40px;\n  font-size: 16px;\n  color: white;\n}\n\n@media (max-width: 768px) {\n  .maintitle {\n    font-size: 24px;\n    letter-spacing: -2px;\n    margin-bottom: 30px;\n    text-align: center;\n    margin-top: 40px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
