// src/components/Content.js
import React, { useEffect, useState } from 'react';
import getImagePath from '../utils/getImagePath';
import './Content.css';
import TitleList from './TitleList';
import { useAtom } from 'jotai';
import { isMobileAtom, languageAtom } from '../App';
import CreateGuideItemList from './CreateGuideItemList';
import getLocalizedText from '../utils/getLocalizedText';

const Content = () => {
    const [progressImage, setProgressImage] = useState('');
    const [isMobile, setIsMobile] = useAtom(isMobileAtom);
    const [language] = useAtom(languageAtom);
    
    // const updateImages = () => {
    //     setProgressImage(getImagePath('05'));
    // };

    // const updateScreenType = () => {
    //     setIsMobile(window.innerWidth < 768);  
    // };

    // 모바일
    const templatesItemsMo = ['72', '74'].map(id => getImagePath(id));
    const emojisMo = ['86', '88'].map(id => getImagePath(id));
    const commercialsMo = ['98', '100', '101'].map(id => getImagePath(id));
    const toonsMo = ['110', '112', '113'].map(id => getImagePath(id));
    const thumbnailsMo = ['128', '130'].map(id => getImagePath(id));
    
    // PC
    const templatesItemsPC = ['45'].map(id => getImagePath(id));
    const emojisPC = ['55'].map(id => getImagePath(id));
    const commercialsPC = ['68'].map(id => getImagePath(id));
    const toonsPC = ['81'].map(id => getImagePath(id));
    const thumbnailsPC = ['99'].map(id => getImagePath(id));
    
    // 공통
    const emojisSub = ['/PC/bokhee01.png','/PC/bokhee02.png', '/PC/bokhee01ani.gif','/PC/bokhee04.png','/PC/bokhee05.png'];
    const ThumbnailsGif = ['/PC/복희짱 인트로 (2024.09.23).gif','/PC/복희짱 화면전환(예시).gif','/PC/복희짱 아웃트로 (2024.09.20).gif','/PC/복희짱 도네이션(투명) (2024.09.27).gif','/PC/Image_94.png']


    // 화면 대응
    const templatesItems = isMobile ? templatesItemsMo : templatesItemsPC;
    const emojis = isMobile ? emojisMo : emojisPC;
    const commercials = isMobile ? commercialsMo : commercialsPC;
    const toons = isMobile ? toonsMo : toonsPC;
    const thumbnails = isMobile ? thumbnailsMo : thumbnailsPC;

    useEffect(() => {
        // updateImages();
        // updateScreenType();
        // window.addEventListener('resize', updateImages);
        // window.addEventListener('resize', updateScreenType);
        // return () => {
        //     window.removeEventListener('resize', updateImages);
        //     window.removeEventListener('resize', updateScreenType);
        // };
    }, [setIsMobile]);

    return (
        <main className="content" >
            <h1 id='top' className="maintitle" dangerouslySetInnerHTML={{ __html: getLocalizedText('Title', language) }} />
            {/* <button className="create-avatar-button">
                {getLocalizedText('CreateButton', language)}
            </button> */}

            <CreateGuideItemList />
            <TitleList containerType='container-1' title={getLocalizedText('Templates', language)} titleType="left" itemType={0} items={templatesItems} subitems={null} bg={true}/> 
            <TitleList containerType='container-2' title={getLocalizedText('Emojis', language)} titleType="right" itemType={0} items={emojis} subitems={emojisSub} bg={false}/>
            <TitleList containerType='container-3' title={getLocalizedText('Commercials', language)} titleType="left" itemType={0} items={commercials} subitems={null} bg={true}/>
            <TitleList containerType='container-4' title={getLocalizedText('Toons', language)} titleType="right" itemType={0} items={toons} subitems={null} bg={false}/>
            <TitleList containerType='container-5' title={getLocalizedText('Thumbnails', language)} titleType="left" itemType={1} items={ThumbnailsGif} subitems={null} bg={true}/>
            <TitleList containerType='container-6' title='' titleType="center" itemType={0} items={null} subitems={null} bg={false}/>
        </main>
    );
};

export default Content;
