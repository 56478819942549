// utils/getImagePath.js

const getImagePath = (imageNumber) => {
    const isMobile = window.innerWidth < 768;
    const basePath = isMobile ? '/Mobile' : '/PC';
    const mobilePath = isMobile ? 'Mobile' : '';
    const imagePath = `${basePath}/${mobilePath}Image_${imageNumber}.png`;
    return imagePath;
};

export default getImagePath;
