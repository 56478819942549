// src/components/CreateGuideItemList.js
import React from 'react';
import { useAtom } from 'jotai';
import { isMobileAtom, languageAtom } from '../App';
import getImagePath from '../utils/getImagePath';
import getLocalizedText from '../utils/getLocalizedText'; // 추가
import './CreateGuideItemList.css';

const CreateGuideItemList = () => {
    const [isMobile] = useAtom(isMobileAtom);
    const [language] = useAtom(languageAtom);

    const createGuideItemsMo = [
        { imageNumber: '16', titleKey: 'CreateGuideItem1', imageMain: '24' },
        { imageNumber: '28', titleKey: 'CreateGuideItem2', imageMain: '36' },
        { imageNumber: '40', titleKey: 'CreateGuideItem3', imageMain: '47' },
        { imageNumber: '51', titleKey: 'CreateGuideItem4', imageMain: '59' }
    ];

    const createGuideImagePC = [
        { titleKey: 'CreateGuideItem1', imageMain: '32' },
        { titleKey: 'CreateGuideItem2', imageMain: '33' },
        { titleKey: 'CreateGuideItem3', imageMain: '34' },
        { titleKey: 'CreateGuideItem4', imageMain: '35' }
    ];

    const ArrowImage = getImagePath(31);

    // const createGuideImagePC = getImagePath(33);

    return (
        <div className="createGuideWrap">
            <div className="create-guide-list">
                {console.log(isMobile)}
                {!isMobile ?
                    <>
                        <div className="guide-item-content">
                            <div className="item-box">
                                <span className="guide-item-title">{getLocalizedText(createGuideImagePC[0].titleKey, language)}</span>
                            </div>
                            <img src={getImagePath(createGuideImagePC[0].imageMain)} alt={createGuideImagePC[0].titleKey} className="guide-item-image-main main-1" />
                        </div>

                        <img src={ArrowImage} alt='arrowImage' className="guide-item-arrow" />

                        <div className="guide-item-content">
                            <div className="item-box">
                                <span className="guide-item-title">{getLocalizedText(createGuideImagePC[1].titleKey, language)}</span>
                            </div>
                            <img src={getImagePath(createGuideImagePC[1].imageMain)} alt={createGuideImagePC[1].titleKey} className="guide-item-image-main main-2" />
                        </div>

                        <img src={ArrowImage} alt='arrowImage' className="guide-item-arrow" />

                        <div className="guide-item-content">
                            <div className="item-box">
                                <span className="guide-item-title">{getLocalizedText(createGuideImagePC[2].titleKey, language)}</span>
                            </div>
                            <img src={getImagePath(createGuideImagePC[2].imageMain)} alt={createGuideImagePC[2].titleKey} className="guide-item-image-main main-3" />
                        </div>

                        <img src={ArrowImage} alt='arrowImage' className="guide-item-arrow" />

                        <div className="guide-item-content">
                            <div className="item-box">
                                <span className="guide-item-title">{getLocalizedText(createGuideImagePC[3].titleKey, language)}</span>
                            </div>
                            <img src={getImagePath(createGuideImagePC[3].imageMain)} alt={createGuideImagePC[3].titleKey} className="guide-item-image-main main-4" />
                        </div>
                    </>
                    : 
                    <>
                    {createGuideItemsMo.map((item, index) => (
                        <div key={index} className="guide-item">
                                <div className="guide-item-content">
                                    <div className="item-box">
                                        <img src={getImagePath(item.imageNumber)} alt={item.titleKey} className="guide-item-image-number" />
                                        <span className="guide-item-title">{getLocalizedText(item.titleKey, language)}</span>
                                    </div>
                                    <img src={getImagePath(item.imageMain)} alt={item.titleKey} className={`guide-item-image-main main-${index + 1}`} />
                                </div>
                        </div>
                    ))}
                    </>
                    }
            </div>
        </div>
    );
};

export default CreateGuideItemList;
