import localizationStringsPC from '../data/strings.json';
import localizationStringsMO from '../data/stringsMo.json';

/**
 * 모바일 환경 여부를 확인하는 함수
 * @returns {boolean} - 모바일 환경 여부 (true: 모바일, false: PC)
 */
const isMobile = () => {
    return window.innerWidth <= 768;
};
/**
 * 언어에 맞는 텍스트를 반환하는 함수
 * @param {string} key - JSON 파일의 키값 (예: "Title", "CreateButton")
 * @param {string} language - 현재 선택된 언어 ("Ko", "En", "Jp")
 * @returns {string} - 해당 언어로 변환된 텍스트
 */


const getLocalizedText = (key, language) => {
    const localizationStrings = isMobile() ? localizationStringsMO : localizationStringsPC;
    
    const localizedItem = localizationStrings[key];

    // 해당 언어가 있는지 확인, 없으면 영어 텍스트를 반환
    if (localizedItem && localizedItem[language]) {
        return localizedItem[language];
    }

    // 언어가 없거나 key가 존재하지 않을 때는 기본적으로 영어 텍스트를 반환
    return localizedItem?.En || '';
};

export default getLocalizedText;
