// src/App.js
import React from 'react';
import './App.css';
import Header from './components/Headers';
import Content from './components/Content';
import Footer from './components/Footer';

import { atom } from 'jotai';
export const isMobileAtom = atom(window.innerWidth < 768);
export const languageAtom = atom('En');

const App = () => {
    return (
        <div className="app">
            <Header />
            <Content />
            <Footer />
        </div>
    );
};

export default App;
