// src/components/Footer.js
import React from 'react';
import './Footer.css';
import getLocalizedText from '../utils/getLocalizedText';

const Footer = () => {
    return (
        <footer className="footer" id='bottom'>
            <p><h2 
    className="thumbnail-desc" 
    dangerouslySetInnerHTML={{ 
        __html: `${getLocalizedText("FooterDesc1",'En') }    <a href="mailto:peter_ceo@epicbeginz.com">  peter_ceo@epicbeginz.com</a>   ${getLocalizedText("FooterDesc2", 'En')}` 
    }} 
/>
            </p>
        </footer>
    );
};

export default Footer;
